import React from "react";
import { Layout, Seo } from "components/common";
import { MainContent } from "components/MainContent"
import { IntroRandoAnim } from "components/IntroRandomAnim";

import { ImpressumContent } from "pagesData/impressum";

const Impressum = () => (
  <Layout>
    <Seo />
    <IntroRandoAnim {...ImpressumContent} />
    <MainContent content={ImpressumContent.mainContent} />
  </Layout>
);

export default Impressum;
