import bg1 from "assets/bilder/impressum/AD_9537_rgb.jpg";
import bg2 from "assets/bilder/impressum/IMG_6783_r.jpg";
import bg3 from "assets/bilder/impressum/IMG_6776_rgb.jpg";

export const ImpressumContent = {
  h2: "Impressum",
  subtitle: "Das obligatorische, kleingedruckte",
  bgs: [bg2],
  mainContent: `
    <h1> Verantwortlich für den Inhalt: </h1>

    <p><b>Elektro Huwiler GmbH</b><br>
    Marcel Imhof / Stefan Alberici<br>
    Stallikonerstrasse 73<br>
        8903 Birmensdorf<br>
    Telefon +41 44 777 65 44<br>
    info@elektro-huwiler.ch<br>
    </p>

    <h1>Konzept, Text, Fotografie und Projektleitung:</h1>
    <p>Boris Adolf Photography<br>
    Stallikonerstrasse 81<br>
    8903 Birmensdorf </p>
    
    <h1>Technische Umsetzung und Programmierung: </h1>
    <p>Block Lab GmbH<br>
    Stapferstrasse 43<br>
    8006 Zürich </p>

    <br>

    <p>

Haftungshinweis    <br/> <br/>

Die Inhalte dieser Websites werden mit grösstmöglicher Sorgfalt erstellt. Der Betreiber übernimmt jedoch keine Gewähr für die Richtigkeit, Vollständigkeit, Verfügbarkeit und Aktualität der bereitgestellten Inhalte.
Benutzer dieser Website verwenden sie ausschliesslich auf ihr eigenes Risiko, und der Betreiber übernimmt keinerlei Gewähr in Bezug auf eine solche Verwendung, ob ausdrücklich oder konkludent.
Diese Website enthält Links auf andere Websites. Für den Inhalt und die Funktion dieser verlinkten Websites sind ausschliesslich deren Betreiber verantwortlich.Betreiber der Webseite
<br/> <br/>

Allgemeines / Einleitung    <br/> <br/>

Gestützt auf Artikel 13 der Schweizerischen Bundesverfassung und die datenschutzrechtlichen Bestimmungen des Bundes (Datenschutzgesetz, DSG) hat jede Person Anspruch auf Schutz ihrer Privatsphäre sowie auf Schutz vor Missbrauch ihrer persönlichen Daten. Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
In Zusammenarbeit mit unseren Hosting-Providern bemühen wir uns, die Datenbanken so gut wie möglich vor unberechtigtem Zugriff, Verlust, Missbrauch oder Verfälschung zu schützen.
Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
Durch die Nutzung dieser Website erklären Sie sich mit der Erhebung, Verarbeitung und Nutzung von Daten gemäss der nachfolgenden Beschreibung einverstanden. Diese Website kann grundsätzlich ohne Registrierung besucht werden. Daten wie aufgerufene Seiten oder Namen von aufgerufenen Dateien, Datum und Uhrzeit werden zu statistischen Zwecken auf dem Server gespeichert, ohne dass diese Daten unmittelbar auf Ihre Person bezogen werden. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
Verarbeitung personenbezogener Daten    <br/> <br/>

Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare Person beziehen. Eine betroffene Person ist eine Person, über die personenbezogene Daten verarbeitet werden. Die Verarbeitung umfasst jeden Umgang mit personenbezogenen Daten, unabhängig von den verwendeten Mitteln und Verfahren, insbesondere die Speicherung, Weitergabe, Beschaffung, Löschung, Aufbewahrung, Veränderung, Vernichtung und Verwendung personenbezogener Daten.
Wir verarbeiten personenbezogene Daten in Übereinstimmung mit dem Schweizer Datenschutzrecht. Sofern und soweit die EU-DSGVO anwendbar ist, verarbeiten wir personenbezogene Daten darüber hinaus auf folgenden Rechtsgrundlagen in Verbindung mit Art. 6 (1) GDPR:    <br/> <br/>

lit. a) Verarbeitung personenbezogener Daten mit Einwilligung der betroffenen Person.    <br/> <br/>

lit. b) Verarbeitung personenbezogener Daten zur Erfüllung eines Vertrages mit der betroffenen Person sowie zur Durchführung entsprechender vorvertraglicher Massnahmen.
<br/> <br/>

lit. c) Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung, der wir nach geltendem Recht der EU oder nach geltendem Recht eines Landes, in dem die GDPR ganz oder teilweise anwendbar ist, unterliegen.
<br/> <br/>
lit. d) Verarbeitung personenbezogener Daten zur Wahrung lebenswichtiger Interessen der betroffenen Person oder einer anderen natürlichen Person.
<br/> <br/>lit. f) Verarbeitung personenbezogener Daten zur Wahrung der berechtigten Interessen von uns oder von Dritten, sofern nicht die Grundfreiheiten und Rechte und Interessen der betroffenen Person überwiegen. Zu den berechtigten Interessen gehören insbesondere unser geschäftliches Interesse, unsere Website bereitstellen zu können, die Informationssicherheit, die Durchsetzung eigener Rechtsansprüche und die Einhaltung des schweizerischen Rechts.

<br/> <br/>
Wir verarbeiten personenbezogene Daten für die Dauer, die für den jeweiligen Zweck oder die jeweiligen Zwecke erforderlich ist. Bei längerfristigen Aufbewahrungspflichten aufgrund gesetzlicher und anderer Verpflichtungen, denen wir unterliegen, schränken wir die Bearbeitung entsprechend ein.
Cookies    <br/> <br/>

Diese Website verwendet Cookies. Dabei handelt es sich um kleine Textdateien, die es ermöglichen, spezifische, auf den Nutzer bezogene Informationen auf dem Endgerät des Nutzers zu speichern, während der Nutzer die Website nutzt. Cookies ermöglichen es insbesondere, die Nutzungshäufigkeit und die Anzahl der Nutzer der Seiten zu ermitteln, Verhaltensmuster der Seitennutzung zu analysieren, aber auch, unser Angebot kundenfreundlicher zu gestalten. Cookies bleiben über das Ende einer Browser-Sitzung hinaus gespeichert und können bei einem erneuten Besuch der Seite wieder abgerufen werden. Wenn Sie dies nicht wünschen, sollten Sie Ihren Internet-Browser so einstellen, dass er die Annahme von Cookies verweigert.
Ein genereller Widerspruch gegen die Verwendung von Cookies zu Online-Marketing-Zwecken kann für eine Vielzahl der Dienste, insbesondere beim Tracking, über die US-Seite http://www.aboutads.info/choices/ oder die EU-Seite http://www.youronlinechoices.com/ erklärt werden. Darüber hinaus kann die Speicherung von Cookies durch Deaktivierung in den Browsereinstellungen erreicht werden. Bitte beachten Sie, dass in diesem Fall nicht alle Funktionen dieses Online-Angebots genutzt werden können.
Mit SSL/TLS-Verschlüsselung    <br/> <br/>

Diese Website verwendet aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie z.B. Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL/TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
Wenn die SSL- oder TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten gelesen werden.
Server-Log-Dateien
Der Provider dieser Website erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:    <br/> <br>

Browsertyp und Browserversion    
<br/>
Verwendetes Betriebssystem    
<br/>
Referrer URL   <br/>

Hostname des zugreifenden Rechners    <br/> 

Zeitpunkt der Serveranfrage    <br/> 

Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.    <br/> <br/>

Dienste von Drittanbietern    <br/> <br/>

Diese Website kann Google Maps zur Einbettung von Karten, Google Invisible reCAPTCHA zum Schutz vor Bots und Spam und YouTube zur Einbettung von Videos nutzen.    <br/> <br/>

Diese Dienste der amerikanischen Google LLC verwenden u.a. Cookies, wodurch Daten an Google in die USA übertragen werden, wobei wir davon ausgehen, dass in diesem Zusammenhang allein durch die Nutzung unserer Website kein personenbezogenes Tracking stattfindet.    <br/> <br/>

Google hat sich verpflichtet, einen angemessenen Datenschutz gemäss dem US-amerikanisch-europäischen und dem US-amerikanisch-schweizerischen Privacy Shield zu gewährleisten.    <br/> <br/>

Weitere Informationen finden Sie in den Datenschutzbestimmungen von Google.    <br/> <br/>

Kontaktformular    <br/> <br/>

Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
<br/> <br/>

Rechte der betroffenen Person    <br/> <br/>

Jede betroffene Person hat das Recht, vom Betreiber der Website eine Bestätigung darüber zu verlangen, ob sie betreffende personenbezogene Daten verarbeitet werden. Wenn Sie dieses Bestätigungsrecht ausüben möchten, können Sie sich jederzeit an den Datenschutzbeauftragten wenden.
Auskunftsrecht    <br/> <br/>

Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, vom Betreiber dieser Website jederzeit unentgeltlich Auskunft über die zu ihrer Person gespeicherten Daten und eine Kopie dieser Auskunft zu erhalten. Darüber hinaus kann ggf. Auskunft über Folgendes erteilt werden:
Zwecke der Verarbeitung    <br/> <br/>

Kategorien der verarbeiteten personenbezogenen Daten
Empfänger, an die die personenbezogenen Daten weitergegeben wurden oder werden
wenn möglich, die geplante Dauer der Speicherung der personenbezogenen Daten oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer
das Bestehen eines Rechts auf Berichtigung oder Löschung der sie betreffenden personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den für die Verarbeitung Verantwortlichen oder ein Recht auf Widerspruch gegen eine solche Verarbeitung
das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde    <br/> <br/>

wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden: Alle verfügbaren Informationen über die Herkunft der Daten.
Ausserdem hat die betroffene Person das Recht, darüber informiert zu werden, ob personenbezogene Daten in ein Drittland oder an eine internationale Organisation übermittelt worden sind. Ist dies der Fall, so hat die betroffene Person ausserdem das Recht, Auskunft über die geeigneten Garantien im Zusammenhang mit der Übermittlung zu erhalten.
Wenn Sie von diesem Auskunftsrecht Gebrauch machen möchten, können Sie sich jederzeit an unseren Datenschutzbeauftragten wenden.
Recht auf Berichtigung    <br/> <br/>

Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, die unverzügliche Berichtigung sie betreffender unrichtiger personenbezogener Daten zu verlangen. Darüber hinaus hat die betroffene Person das Recht, unter Berücksichtigung der Zwecke der Verarbeitung, die Vervollständigung unvollständiger personenbezogener Daten – auch mittels einer ergänzenden Erklärung – zu verlangen.
Wenn Sie dieses Recht auf Berichtigung ausüben möchten, können Sie sich jederzeit an unseren Datenschutzbeauftragten wenden.
Recht auf Löschung (Recht auf Vergessenwerden)    <br/> <br/>

Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, von dem für die Verarbeitung Verantwortlichen dieser Website die unverzügliche Löschung der sie betreffenden personenbezogenen Daten zu verlangen, sofern einer der folgenden Gründe zutrifft und die Verarbeitung nicht mehr erforderlich ist:
Die personenbezogenen Daten wurden für Zwecke erhoben oder auf sonstige Weise verarbeitet, für die sie nicht mehr erforderlich sind.
Die betroffene Person widerruft die Einwilligung, auf der die Verarbeitung beruhte, und es gibt keine andere Rechtsgrundlage für die Verarbeitung
Die betroffene Person legt aus Gründen, die sich aus ihrer besonderen Situation ergeben, Widerspruch gegen die Verarbeitung ein, und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder die betroffene Person legt im Falle von Direktwerbung und damit verbundenem Profiling Widerspruch gegen die Verarbeitung ein
Die personenbezogenen Daten wurden unrechtmässig verarbeitet    <br/> <br/>

Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der für die Verarbeitung Verantwortliche unterliegt
Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft erhoben, die direkt an ein Kind gerichtet sind
Wenn einer der oben genannten Gründe zutrifft und Sie die Löschung von personenbezogenen Daten, die beim Betreiber dieser Website gespeichert sind, veranlassen möchten, können Sie sich jederzeit an unseren Datenschutzbeauftragten wenden. Der Datenschutzbeauftragte dieser Website wird veranlassen, dass dem Löschverlangen unverzüglich nachgekommen wird.
Recht auf Einschränkung der Verarbeitung    <br/> <br/>

Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, von dem für die Verarbeitung Verantwortlichen dieser Website die Einschränkung der Verarbeitung zu verlangen, wenn eine der folgenden Bedingungen erfüllt ist:
Die Richtigkeit der personenbezogenen Daten wird von der betroffenen Person bestritten, und zwar für einen Zeitraum, der es dem für die Verarbeitung Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen
Die Verarbeitung ist unrechtmässig, die betroffene Person legt Widerspruch gegen die Löschung der personenbezogenen Daten ein und verlangt stattdessen die Einschränkung der Nutzung der personenbezogenen Daten
Der für die Verarbeitung Verantwortliche benötigt die personenbezogenen Daten nicht mehr für die Zwecke der Verarbeitung, die betroffene Person benötigt sie jedoch für die Geltendmachung, Die betroffene Person hat aus Gründen, die sich aus ihrer besonderen Situation ergeben, Widerspruch gegen die Verarbeitung eingelegt, und es steht noch nicht fest, ob die berechtigten Interessen des Verantwortlichen gegenüber denen der betroffenen Person überwiegen
Wenn eine der vorgenannten Voraussetzungen gegeben ist, können Sie sich jederzeit an unseren Datenschutzbeauftragten wenden, um die Einschränkung der Verarbeitung personenbezogener Daten beim Betreiber dieser Website zu verlangen. Der Datenschutzbeauftragte dieser Website wird die Einschränkung der Verarbeitung veranlassen.
Recht auf Datenübertragbarkeit    <br/> <br/>

Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, die sie betreffenden personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Darüber hinaus hat die betroffene Person das Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem für die Verarbeitung Verantwortlichen an einen anderen für die Verarbeitung Verantwortlichen übermittelt werden, sofern dies technisch machbar ist und sofern dadurch nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden.
Um das Recht auf Datenübertragbarkeit geltend zu machen, können Sie sich jederzeit an den vom Betreiber dieser Website benannten Datenschutzbeauftragten wenden.
Ein Widerspruchsrecht    <br/> <br/>

Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung sie betreffender personenbezogener Daten Widerspruch einzulegen.
Der Betreiber dieser Website wird die personenbezogenen Daten im Falle des Widerspruchs nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die die Interessen, Rechte und Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
Um von Ihrem Widerspruchsrecht Gebrauch zu machen, können Sie sich direkt an den Datenschutzbeauftragten dieser Website wenden.
Recht auf Widerruf einer datenschutzrechtlichen Einwilligung
Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, eine erteilte Einwilligung in die Verarbeitung personenbezogener Daten jederzeit zu widerrufen.
Wenn Sie von Ihrem Recht auf Widerruf einer Einwilligung Gebrauch machen möchten, können Sie sich jederzeit an unseren Datenschutzbeauftragten wenden.
Widerspruch E-Mail Marketing    <br/> <br/>

Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
Haftungsausschluss    <br/> <br/>

Alle Angaben auf unserer Website wurden sorgfältig geprüft. Wir sind bemüht, dafür Sorge zu tragen, dass die von uns bereitgestellten Informationen aktuell, richtig und vollständig sind. Dennoch ist das Auftreten von Fehlern nicht völlig auszuschliessen, so dass wir für die Vollständigkeit, Richtigkeit und Aktualität der Informationen, auch journalistisch-redaktioneller Art, keine Gewähr übernehmen können. Haftungsansprüche, die sich auf Schäden materieller oder ideeller Art beziehen, welche durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen.
Der Herausgeber kann Texte nach eigenem Ermessen und ohne vorherige Ankündigung ändern oder löschen und ist nicht dazu verpflichtet, die Inhalte dieser Website zu aktualisieren. Die Nutzung dieser Website bzw. der Zugang zu ihr erfolgt auf eigenes Risiko des Besuchers. Der Herausgeber, seine Kunden oder Partner sind nicht verantwortlich für Schäden, wie z.B. direkte, indirekte, zufällige oder Folgeschäden, die angeblich durch den Besuch dieser Website verursacht wurden und übernehmen folglich keine Haftung für solche Schäden.
Der Herausgeber übernimmt auch keine Verantwortung oder Haftung für den Inhalt und die Verfügbarkeit von Websites Dritter, die über externe Links von dieser Website aus erreicht werden können. Für den Inhalt der verlinkten Seiten sind ausschliesslich deren Betreiber verantwortlich. Der Herausgeber distanziert sich daher ausdrücklich von allen fremden Inhalten, die möglicherweise straf- oder haftungsrechtlich relevant sind oder gegen die guten Sitten verstossen.
Google Maps    <br/> <br/>

Diese Website nutzt das Angebot von Google Maps. Dies ermöglicht es uns, interaktive Karten direkt auf der Website darzustellen und Ihnen die komfortable Nutzung der Kartenfunktion zu ermöglichen. Durch den Besuch der Website erhält Google die Information, dass Sie die entsprechende Unterseite unserer Website aufgerufen haben. Dies geschieht unabhängig davon, ob Google ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder ob kein Nutzerkonto vorhanden ist. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung zu Ihrem Profil bei Google nicht wünschen, müssen Sie sich vor Aktivierung der Schaltfläche ausloggen. Google speichert Ihre Daten als Nutzungsprofile und nutzt sie für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche Auswertung erfolgt insbesondere (auch für nicht eingeloggte Nutzer) zur Erbringung bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu informieren. Sie haben das Recht, der Erstellung dieser Nutzerprofile zu widersprechen, wobei Sie sich zur Ausübung dieses Rechts an Google wenden müssen. Nähere Informationen zu Zweck und Umfang der Datenerhebung und -verarbeitung durch Google sowie weitere Informationen zu Ihren diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre finden Sie unter: www.google.de/intl/de/policies/privacy.
Google Ads    <br/> <br/>

Diese Website verwendet Google Conversion Tracking. Wenn Sie über eine von Google geschaltete Anzeige auf unsere Website gelangt sind, wird von Google Ads ein Cookie auf Ihrem Computer gesetzt. Das Cookie für das Conversion-Tracking wird gesetzt, wenn ein Nutzer auf eine von Google geschaltete Anzeige klickt. Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit und werden nicht zur persönlichen Identifizierung verwendet. Wenn der Nutzer bestimmte Seiten unserer Website besucht und das Cookie noch nicht abgelaufen ist, können wir und Google erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde. Jeder Google Ads-Kunde erhält ein anderes Cookie. Cookies können daher nicht über die Websites der Ads-Kunden hinweg nachverfolgt werden. Die mithilfe des Conversion-Cookies gewonnenen Informationen werden verwendet, um Conversion-Statistiken für Ads-Kunden zu erstellen, die sich für das Conversion-Tracking entschieden haben. Die Kunden erfahren die Gesamtzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine Informationen, die zur persönlichen Identifizierung von Nutzern verwendet werden können.
Wenn Sie nicht am Tracking teilnehmen möchten, können Sie das Setzen eines dafür erforderlichen Cookies verweigern – zum Beispiel durch eine Browser-Einstellung, die das automatische Setzen von Cookies generell deaktiviert, oder indem Sie Ihren Browser so einstellen, dass Cookies von der Domain “googleleadservices.com” blockiert werden.
Bitte beachten Sie, dass Sie die Opt-Out-Cookies nicht löschen dürfen, solange Sie nicht möchten, dass Messdaten erfasst werden. Wenn Sie alle Ihre Cookies im Browser gelöscht haben, müssen Sie das jeweilige Opt-Out-Cookie erneut setzen.
Google reCAPTCHA    <br/> <br/>

Diese Website verwendet den reCAPTCHA-Dienst von Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland “Google”). Die Abfrage dient dazu, zu unterscheiden, ob die Eingabe durch einen Menschen oder durch eine automatisierte, maschinelle Verarbeitung erfolgt. Die Abfrage beinhaltet die Übermittlung der IP-Adresse und ggf. weiterer von Google für den reCAPTCHA-Dienst benötigter Daten an Google. Zu diesem Zweck wird Ihre Eingabe an Google übermittelt und dort weiterverwendet. Ihre IP-Adresse wird von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung dieses Dienstes auszuwerten. Die im Rahmen von reCaptcha von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Ihre Daten können dabei auch in die USA übertragen werden. Für Datenübermittlungen in die USA gibt es einen Angemessenheitsbeschluss der Europäischen Kommission, das “Privacy Shield”. Google nimmt an dem “Privacy Shield” teil und hat sich den Anforderungen unterworfen. Durch Betätigen der Abfrage stimmen Sie der Verarbeitung Ihrer Daten zu. Die Verarbeitung erfolgt auf Grundlage von Art. 6 (1) lit. a DSGVO mit Ihrer Einwilligung. Sie können Ihre Einwilligung jederzeit widerrufen, ohne dass die Rechtmässigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.
Weitere Informationen zu Google reCAPTCHA und den dazugehörigen Datenschutzbestimmungen finden Sie unter: https://policies.google.com/privacy?hl=de
Google Analytics    <br/> <br/>

Diese Website benutzt Google Analytics, einen Webanalysedienst, der von Google Ireland Limited bereitgestellt wird. Befindet sich der für die Datenverarbeitung auf dieser Website Verantwortliche ausserhalb des Europäischen Wirtschaftsraums oder der Schweiz, so wird die Datenverarbeitung von Google Analytics von Google LLC durchgeführt. Google LLC und Google Ireland Limited werden im Folgenden als “Google” bezeichnet.
Die gewonnenen Statistiken ermöglichen es uns, unser Angebot zu verbessern und für Sie als Nutzer interessanter zu gestalten. Diese Website verwendet Google Analytics auch für eine geräteübergreifende Analyse der Besucherströme, die über eine Benutzerkennung erfolgt. Wenn Sie über ein Google-Benutzerkonto verfügen, können Sie die geräteübergreifende Analyse Ihrer Nutzung in den dortigen Einstellungen unter “Meine Daten”, “Persönliche Daten” deaktivieren.
Die Rechtsgrundlage für den Einsatz von Google Analytics ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Wir weisen Sie darauf hin, dass auf dieser Website Google Analytics um den Code “_anonymizeIp();” erweitert wurde, um eine anonymisierte Erfassung von IP-Adressen zu gewährleisten. Das bedeutet, dass IP-Adressen in gekürzter Form verarbeitet werden, so dass sie nicht mit einer bestimmten Person in Verbindung gebracht werden können. Sollten die über Sie erhobenen Daten einer Person zugeordnet werden können, wird dies sofort ausgeschlossen und die personenbezogenen Daten werden umgehend gelöscht.
Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Für die Ausnahmefälle, in denen personenbezogene Daten in die USA übertragen werden, hat sich Google dem EU-US Privacy Shield unterworfen, https://www.privacyshield.gov/EU-US-Framework.
Google Analytics verwendet Cookies. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: Google Analytics deaktivieren.
Darüber hinaus können Sie auch die Nutzung von Google Analytics verhindern, indem Sie auf diesen Link klicken: Google Analytics deaktivieren. Dadurch wird ein sog. Opt-Out-Cookie auf Ihrem Datenträger gespeichert, der die Verarbeitung personenbezogener Daten durch Google Analytics verhindert. Bitte beachten Sie, dass beim Löschen aller Cookies auf Ihrem Endgerät auch diese Opt-Out-Cookies gelöscht werden, d.h. Sie müssen die Opt-Out-Cookies erneut setzen, wenn Sie diese Form der Datenerhebung weiterhin verhindern wollen. Die Opt-Out-Cookies werden pro Browser und Computer/Endgerät gesetzt und müssen daher für jeden Browser, Computer oder jedes andere Endgerät separat aktiviert werden.
Google WebFonts
Diese Website verwendet für die einheitliche Darstellung von Schriftarten sogenannte Webfonts, die von Google bereitgestellt werden. Wenn Sie eine Seite aufrufen, lädt Ihr Browser die benötigten Web Fonts in seinen Browser-Cache, um Texte und Schriften korrekt darzustellen. Wenn Ihr Browser keine Web Fonts unterstützt, wird eine Standardschriftart von Ihrem Computer verwendet.
Weitere Informationen zu Google Web Fonts finden Sie unter https://developers.google.com/fonts/faq und in den Datenschutzbestimmungen von Google: https://www.google.com/policies/privacy/
Monotype-Schriften    <br/> <br/>

Diese Website nutzt Fonts.com, einen von Monotype Imaging Holdings Inc. bereitgestellten Dienst zur Visualisierung von Schriften, der es dieser Website ermöglicht, entsprechende Inhalte auf ihren Seiten einzubetten. Erfasste persönliche Daten: Nutzungsdaten und verschiedene Arten von Daten, wie in der Datenschutzrichtlinie des Dienstes beschrieben. Ort der Verarbeitung: Vereinigte Staaten von Amerika (USA); Datenschutzrichtlinien.
Agenturleistungen
Wir verarbeiten die Daten unserer Kunden gemäss den Datenschutzbestimmungen des Bundes (Datenschutzgesetz, DSG) und der EU-DSGVO im Rahmen unserer vertraglichen Leistungen. Dabei verarbeiten wir Bestandsdaten (z.B. Kundenstammdaten, wie Namen oder Adressen), Kontaktdaten (z.B., E-Mail, Telefonnummern), Inhaltsdaten (z.B. Texteingaben, etc.), Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit), Zahlungsdaten (z.B. Bankverbindung, Zahlungsverhalten), Nutzungsdaten und Metadaten (z.B. im Rahmen der Auswertung und Erfolgsmessung von Marketingmassnahmen). Zu den betroffenen Personen gehören unsere Kunden, Interessenten sowie deren Kunden, Nutzer, Website-Besucher oder Mitarbeiter sowie Dritte. Zweck der Verarbeitung ist die Erbringung der vertraglichen Leistungen, die Abrechnung und unser Kundenservice. Die Rechtsgrundlage für die Verarbeitung ergibt sich aus Art. 6 Abs.. 1 lit. b DSGVO (vertragliche Leistungen), Art. 6 Abs.. 1 lit. f DSGVO (Analyse, Statistik, Optimierung, Sicherheitsmassnahmen). Wir verarbeiten Daten, die für die Begründung und Erfüllung vertraglicher Leistungen erforderlich sind und weisen auf die Erforderlichkeit ihrer Weitergabe hin. Eine Weitergabe an Externe erfolgt nur, wenn dies im Rahmen einer Bestellung erforderlich ist. Bei der Verarbeitung der uns im Rahmen eines Auftrages zur Verfügung gestellten Daten handeln wir nach den Weisungen des Auftraggebers sowie den gesetzlichen Vorgaben einer Auftragsverarbeitung gemäss Art. 28 DSGVO und verarbeiten die Daten nicht zu anderen als den im Auftrag genannten Zwecken.
Wir löschen die Daten nach Ablauf der gesetzlichen Gewährleistungs- und vergleichbaren Pflichten. Die Erforderlichkeit der Aufbewahrung der Daten wird in unregelmässigen Abständen überprüft. Im Falle von gesetzlichen Archivierungspflichten erfolgt die Löschung nach deren Ablauf. Bei Daten, die uns der Auftraggeber im Rahmen eines Auftrages überlassen hat, löschen wir die Daten entsprechend den Vorgaben des Auftrages, in der Regel nach Beendigung des Auftrages.
Vertragliche Leistungen
Wir verarbeiten die Daten unserer Vertragspartner und Interessenten sowie sonstiger Auftraggeber, Kunden, Klienten oder Vertragspartner (einheitlich als “Vertragspartner” bezeichnet) im Einklang mit den datenschutzrechtlichen Bestimmungen des Bundesdatenschutzgesetzes (DSG) und der EU-DSGVO gemäss Art. 6 Abs. 1 lit. b. DSGVO, um ihnen unsere vertraglichen oder vorvertraglichen Leistungen zu erbringen. Die in diesem Zusammenhang verarbeiteten Daten, Art, Umfang und Zweck sowie die Erforderlichkeit ihrer Verarbeitung ergeben sich aus dem zugrundeliegenden Vertragsverhältnis.Zu den verarbeiteten Daten gehören die Stammdaten unserer Vertragspartner (z.B. Namen und Adressen), Kontaktdaten (z.B. E-Mail-Adressen und Telefonnummern) sowie Vertragsdaten (z.B. in Anspruch genommene Leistungen, Vertragsinhalte, Vertragskommunikation, Namen von Ansprechpartnern) und Zahlungsdaten (z. Wir verarbeiten grundsätzlich keine besonderen Kategorien personenbezogener Daten, es sei denn, diese sind Bestandteil einer Auftrags- oder Vertragsabwicklung.
Wir verarbeiten Daten, die zur Begründung und Erfüllung der vertraglichen Leistungen erforderlich sind und weisen auf die Notwendigkeit ihrer Weitergabe hin, es sei denn, dies ist für die Vertragspartner offensichtlich. Eine Weitergabe an externe Personen oder Unternehmen erfolgt nur, wenn dies im Rahmen eines Vertrages erforderlich ist. Bei der Verarbeitung der uns im Rahmen eines Auftrages überlassenen Daten handeln wir nach den Weisungen des Auftraggebers sowie den gesetzlichen Bestimmungen.
Im Rahmen der Nutzung unserer Online-Dienste können wir die IP-Adresse und den Zeitpunkt der jeweiligen Nutzeraktion speichern. Die Speicherung erfolgt auf Grundlage unserer berechtigten Interessen sowie der Interessen der Nutzer am Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, es sei denn, sie ist für die Verfolgung unserer Ansprüche nach Art. 6 Abs.. 1 lit. f. DSGVO erforderlich ist oder eine gesetzliche Verpflichtung dazu besteht gemäss Art. 6 Abs.. 1 lit. c. DSGVO.
Die Löschung der Daten erfolgt, wenn die Daten zur Erfüllung vertraglicher oder gesetzlicher Sorgfaltspflichten sowie zur Abwicklung etwaiger Gewährleistungs- und vergleichbarer Verpflichtungen nicht mehr erforderlich sind, wobei die Erforderlichkeit der Aufbewahrung der Daten in unregelmässigen Abständen überprüft wird. Im Übrigen gelten die gesetzlichen Aufbewahrungspflichten.
Datenübermittlung in die USA
Auf unserer Website sind u.a. Tools von Unternehmen mit Sitz in den USA eingebunden. Wenn diese Tools aktiv sind, können Ihre personenbezogenen Daten an die US-Server der jeweiligen Unternehmen übertragen werden. Wir weisen darauf hin, dass die USA kein sicherer Drittstaat im Sinne des EU-Datenschutzrechts sind. US-Unternehmen sind verpflichtet, personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener dagegen rechtlich vorgehen können. Es kann daher nicht ausgeschlossen werden, dass US-Behörden (z.B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Auf diese Verarbeitungsvorgänge haben wir keinen Einfluss.
Änderungen
Wir können diese Datenschutzrichtlinie jederzeit ohne vorherige Ankündigung ändern. Es gilt die jeweils aktuelle, auf unserer Website veröffentlichte Fassung. Soweit die Datenschutzerklärung Teil einer Vereinbarung mit Ihnen ist, werden wir Sie im Falle einer Aktualisierung per E-Mail oder auf andere geeignete Weise über die Änderung informieren.
Haftungsausschluss    <br/> <br/>

Der Autor übernimmt keine Gewähr für die Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und Vollständigkeit der Informationen.
Haftungsansprüche gegen den Autor wegen Schäden materieller oder immaterieller Art, die aus dem Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der Verbindung oder durch technische Störungen entstanden sind, werden ausgeschlossen.
Alle Angebote sind freibleibend. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.



    </p>
    `,
};
